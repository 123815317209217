//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ChartComponent',
  components: {
    BarChart: () => import('./BarChart'),
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    data: {
      required: true,
    },
  },
}
